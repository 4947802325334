import * as React from "react";
import {useRef, useState} from "react";
import RichTextBlock from "../cms/RichTextBlock";
import {graphql, useStaticQuery} from "gatsby";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

export default function TermsConditions({open, onClose}) {
  const query = graphql`
  query TermsConditions {
    terms: strapiTermsConditions {
      Title
      richText: childComponentSharedRichText {
        childMdx {
          body
        }
      }
    }
  }
  `;
  const {terms} = useStaticQuery(query);
  const dialogRef = useRef(null);
  const [bottom, setBottom] = useState(false);

  const reachedBottom = () => {
    const element = dialogRef.current;
    const hitBottom = Math.trunc(element?.scrollTop) === (element?.scrollHeight - element?.offsetHeight);
    if (hitBottom && !bottom) {
      setBottom(true);
    }
  };
  return (
      <Dialog fullWidth
              open={open}
              onClose={onClose}>
        <DialogTitle>{terms.Title}</DialogTitle>
        <DialogContent onScroll={reachedBottom}
                       ref={dialogRef}>
          <RichTextBlock body={terms.richText.childMdx.body}/>
        </DialogContent>
        <DialogActions sx={{justifyContent: "space-evenly"}}>
          <Button onClick={() => onClose(false)}
          >Refuse</Button>
          <Button onClick={() => onClose(true)}
                  variant="contained"
                  disabled={!bottom}
          >Accept</Button>
        </DialogActions>
      </Dialog>
  );
}
