import * as React from "react";
import {useState} from "react";
// @ts-ignore
import MetaMaskIcon from "../../../images/metamask.svg";
import TextField from "@mui/material/TextField";
import loadable from "@loadable/component";

const Web3 = loadable.lib(() => import ("web3-utils"));

export interface ParticipateAmount {
  callback: any;
}

export default function TokenAmount(props: ParticipateAmount) {
  const isSSR = typeof window === "undefined";
  if (isSSR) {
    return null;
  }
  const [amount] = useState<any>();

  const onAmountChange = (web3: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value) {
      props.callback(web3.toHex(web3.toWei(event.target.value)));
    }
  };

  const textField: any = ({default: web3}) => {
    return (
        <>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Amount"
              type="number"
              fullWidth
              variant="outlined"
              value={amount}
              onChange={onAmountChange(web3)}
          />
        </>
    );
  };

  return (
      <div>
        <Web3>
          {textField}
        </Web3>
      </div>
  );
}
