import * as React from "react";
// @ts-ignore
import MetaMaskIcon from "../../../images/metamask.svg";
import Grid from "@mui/material/Grid";
import {IGatsbyImageData} from "gatsby-plugin-image";
import BSCProvider from "./BSCProvider";
import ProviderMM from "./ProviderMM";

const bscProviderComponents = {
  "MetaMask": ProviderMM,
};

interface BscProviderProps {
  id: string;
  name: string;
  cover: any;
  richText: any;
}


interface CoinProps {
  symbol: string;
  cover: IGatsbyImageData;
  bscProviders: BscProviderProps[];
  wallet: string;
}

export default function Coin({
  bscProviders,
  wallet,
}: CoinProps) {
  return (
      <>
        <Grid container
              spacing={3}
              justifyContent="center">
          {bscProviders &&
              bscProviders.map((provider) => {
                return (
                    <Grid key={provider.id}
                          item
                          xs={12} md={6} lg={6}>
                      <BSCProvider name={provider.name}
                                   mdxBody={provider.richText.childMdx.body}
                                   cover={provider.cover?.file.childImageSharp.gatsbyImageData}
                                   wallet={wallet}
                                   bscComponent={bscProviderComponents[provider.name]}
                      />
                    </Grid>
                );
              })
          }
        </Grid>
      </>
  );
}
