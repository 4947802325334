import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {Balance, getBscBalance, getOrders, Order} from "../../state/store";
import {onSnapshot} from "firebase/firestore";
import {FirebaseContext} from "../../state/firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {getFunctions, httpsCallable} from "firebase/functions";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import {bscConfig} from "../../state/firebase/config";
import {EthValue} from "./EthValue";
import Grid from "@mui/material/Grid";
import Autorenew from "@mui/icons-material/Autorenew";
import Box from "@mui/material/Box";
import {TableFooter, useMediaQuery} from "@mui/material";
import Title from "./Title";
import LoadingButton from "@mui/lab/LoadingButton";
import {useTheme} from "@mui/material/styles";

const deduplicate = (items: Order[]) => {
  return items.filter((item, i) => items.map(val => val.id).indexOf(item.id) == i);
};

interface OrdersProps {
  title: string;
  forUser: boolean;
}

export default function Orders({title, forUser}: OrdersProps) {
  const [items, setItems] = useState<Order[]>([]);
  const [checkingOrders, setCheckingOrders] = useState(false);
  const firebase = useContext(FirebaseContext);
  const [user, loading] = useAuthState(getAuth(firebase.firebase));
  const [userBalance, setUserBalance] = useState<Balance | null>({
    oraAmount: "0",
    amount: "0",
  });

  useEffect(() => {
    onSnapshot(getOrders(firebase, forUser ? user : null), snapshot => {
      const listItems = snapshot.docs.map(doc => doc.data());
      setItems(deduplicate([...items, ...listItems]));
    });
    if (forUser) {
      onSnapshot(getBscBalance(firebase, user), snapshot => {
        const balanceResult = snapshot.docs.map(doc => doc.data());
        if (balanceResult.length) {
          setUserBalance({
            ...balanceResult[0],
          });
        }
      });
    }
  }, [loading]);


  const refreshTransactions = () => {
    const functions = getFunctions(firebase.firebase, "europe-west1");
    const checkOrders = httpsCallable(functions, "verifyOrdersForUser");
    setCheckingOrders(true);
    checkOrders()
        .catch((error) => {
          // Getting the Error details.
          const code = error.code;
          const message = error.message;
          const details = error.details;
          console.error(error);
        })
        .finally(() => setCheckingOrders(false));
  };

  const theme = useTheme();
  const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));
  return (
      <React.Fragment>
        <Paper sx={{
          width: "100%",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}>
          <Grid container
                justifyContent={"space-between"}
                sx={{p: 2}}>
            <Grid item>
              <Title>
                {title}
              </Title>
            </Grid>
            {forUser &&
              <Grid item alignSelf={"center"}>
                <Box>
                  <LoadingButton
                    loading={checkingOrders}
                    loadingPosition={isXsScreen ? "center" : "end"}
                    endIcon={<Autorenew/>}
                    variant={"contained"}
                    type="submit"
                    onClick={refreshTransactions}
                  >
                    <Box component={"div"}
                         sx={{
                           display: {xs: "none", sm: "inline-flex"},
                         }}
                    >
                      Refresh
                    </Box>
                  </LoadingButton>
                </Box>
              </Grid>
            }
          </Grid>
          <TableContainer sx={{maxHeight: 440}}>
            <Table size="small"
                   sx={{
                     "& .MuiTableCell-root": {
                       p: {
                         xs: "6px 10px",
                         sm: "6px 16px",
                       },
                     },
                   }}>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell>BNB</TableCell>
                  <TableCell>ORA</TableCell>
                  <TableCell>TxID</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items.map((row, idx) => (
                    <TableRow key={row.id + title}>
                      <TableCell align="left">{row.date}</TableCell>
                      <TableCell>
                        <EthValue bnbAmount={row.bnbAmount}/>
                      </TableCell>
                      <TableCell>
                        <EthValue oraAmount={row.oraAmount}/>
                      </TableCell>
                      <TableCell>
                        <Typography component="p" sx={{
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          maxWidth: "30vw",
                        }}>
                          <Link href={bscConfig.base + bscConfig.tx + row.txid}>
                            {row.txid}
                          </Link>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Chip label={row.status}
                              color={row.status == "Success" ? "success" : "warning"}/>
                      </TableCell>
                    </TableRow>
                ))}
              </TableBody>
              {forUser && (<TableFooter>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body1"
                                    style={{fontWeight: 600}}>
                          Total
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1"
                                    style={{fontWeight: 600}}>
                          <EthValue bnbAmount={userBalance.amount}/>
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1"
                                    style={{fontWeight: 600}}>
                          <EthValue oraAmount={userBalance.oraAmount}/>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableFooter>
              )}
            </Table>
          </TableContainer>
        </Paper>
      </React.Fragment>
  );
}
