import {fromWei} from "web3-utils";

import * as React from "react";


export interface EthValueProps {
  bnbAmount?: string;
  oraAmount?: string;
}

export const EthValue = ({bnbAmount, oraAmount}: EthValueProps) => {
  const isSSR = typeof window === "undefined";
  if (isSSR || (!bnbAmount && !oraAmount)) {
    return null;
  }

  const getValue: any = () => {
    return fromWei(bnbAmount || oraAmount);
  };

  return (
      <>
        {getValue()}
      </>
  );
};
