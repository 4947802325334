import ButtonBase from "@mui/material/ButtonBase";
import * as React from "react";
// @ts-ignore
import MetaMaskIcon from "../../../images/metamask.svg";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import RichTextBlock from "../../cms/RichTextBlock";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";
import {DialogActions} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CopyAll from "@mui/icons-material/CopyAll";
import Grid from "@mui/material/Grid";

interface BscProviderProps {
  name: string;
  mdxBody: any;
  cover: IGatsbyImageData;
  wallet: string;
  bscComponent?: React.FC<BscComponentProps>;
}

export interface BscComponentProps {
  wallet: string;
}

export default function BSCProvider({
  name,
  mdxBody,
  cover,
  wallet,
  bscComponent: BscComponent,
}: BscProviderProps) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCopy = () => {
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(wallet);
  };

  return (
      <>
        <Paper variant="outlined">
          <ButtonBase focusRipple
                      onClick={handleClickOpen}
                      sx={{minWidth: 250, width: "100%"}}>
            {cover && (
                <GatsbyImage image={cover} alt={name}/>
            )}
          </ButtonBase>
        </Paper>
        <Dialog open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={"md"}>
          <DialogTitle sx={{textAlign: "center"}}>
            {cover && (
                <GatsbyImage image={cover}
                             alt={name}/>
            )}
          </DialogTitle>
          <DialogContent>
            <RichTextBlock body={mdxBody}/>
          </DialogContent>
          <DialogActions>
            <Grid container
                  spacing={3}
                  justifyContent="space-evenly"
                  wrap={"wrap"}>
              <Grid item xs={12} md={6} lg={6}>
                <FormControl variant="outlined"
                             fullWidth>
                  <InputLabel htmlFor="intermediate-address">
                    Destination Address
                  </InputLabel>
                  <OutlinedInput
                      id="intermediate-address"
                      disabled
                      value={wallet}
                      label="Destination Address"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="copy address"
                              onClick={handleCopy}
                              edge="end"
                              disabled={false}
                          >
                            <CopyAll/>
                          </IconButton>
                        </InputAdornment>
                      }
                  />
                </FormControl>
              </Grid>
              {BscComponent && (
                  <Grid item xs={12} md={6} lg={6}
                        container
                        justifyContent="center"
                        alignContent="center">
                    <Grid item>
                      <BscComponent wallet={wallet}/>
                    </Grid>
                  </Grid>
              )}
            </Grid>
          </DialogActions>
        </Dialog>
      </>
  );
}
