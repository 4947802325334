import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "@firebase/firestore";
import firebase from "firebase/compat";
import {AccountInfo, Balance, Globals, Order} from "../store";

export const accountConverter: FirestoreDataConverter<AccountInfo> = {
  toFirestore(account: AccountInfo): firebase.firestore.DocumentData {
    return {...account};
  },

  fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions): AccountInfo {
    const data = snapshot.data(options)!;
    return {...data} as AccountInfo;
  },
};

export const orderConverter: FirestoreDataConverter<Order> = {
  toFirestore(account: Order): firebase.firestore.DocumentData {
    return {...account};
  },

  fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions): Order {
    const order = snapshot.data(options)!;
    return {
      id: snapshot.id,
      date: new Date(Number.parseInt(order.timeStamp) * 1000).toLocaleDateString(),
      bnbAmount: order.value,
      oraAmount: order.oraValue,
      status: order.txreceipt_status === "1" ? "Success" : "Pending",
      txid: order.hash,
    };
  },
};

export const balanceConverter: FirestoreDataConverter<Balance> = {
  toFirestore(balance: Balance): firebase.firestore.DocumentData {
    return {...balance};
  },

  fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions): Balance {
    const balance = snapshot.data(options)!;
    return {
      amount: balance.balance,
      oraAmount: balance.oraBalance
    };
  },
};

export const totalConverter: FirestoreDataConverter<string> = {
  toFirestore(balance: string): firebase.firestore.DocumentData {
    return {totalSold: balance};
  },

  fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions): string {
    const balance = snapshot.data(options)!;
    return balance.totalSold;
  },
};

export const globalsConverter: FirestoreDataConverter<Globals> = {
  toFirestore(globals: Globals): firebase.firestore.DocumentData {
    return {...globals};
  },

  fromFirestore(
      snapshot: QueryDocumentSnapshot,
      options: SnapshotOptions): Globals {
    const data = snapshot.data(options)!;
    return {...data} as Globals;
  },
};
