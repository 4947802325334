import * as React from "react";
import {Router} from "@reach/router";
import PrivateRoute from "../components/PrivateRoute";
import Login from "../components/auth/Login";
import Dashboard from "../components/dashboard/Dashboard";
import {graphql, withPrefix} from "gatsby";
import Account from "../components/Account";
import Register from "../components/auth/Register";
import RecoverPassword from "../components/auth/RecoverPassword";
import {I18nextContext} from "gatsby-plugin-react-i18next";
import {I18NextContext} from "gatsby-plugin-react-i18next/dist/types";

const App = ({data}) => {
  const context: I18NextContext = React.useContext(I18nextContext);
  if (context.defaultLanguage === context.language) {
    return (
        <Router basepath={withPrefix("/app")}>
          <Login path="/login"/>
          <Register path="/register"/>
          <RecoverPassword path="/forgot-password"/>
          <PrivateRoute default path="/dashboard"
                        component={Dashboard}
                        articles={data.articles}
          />
          <PrivateRoute path="/account"
                        component={Account}
                        articles={data.articles}
          />
        </Router>
    );
  }
  return (
      <Router basepath={withPrefix("/:lang/app")}>
        <Login default path="/login"/>
        <Register path="/register"/>
        <RecoverPassword path="/forgot-password"/>
        <PrivateRoute path="/dashboard"
                      component={Dashboard}
                      articles={data.articles}
        />
        <PrivateRoute path="/account"
                      component={Account}
                      articles={data.articles}
        />
      </Router>
  );
};

export default App;

export const query = graphql`
query ($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  articles: allStrapiArticle(
    filter: {locale: {eq: $language}, article: {id: {eq: null}}}
    sort: {fields: [order], order: ASC}
  ) {
    edges {
      node {
        strapiId
        locale
        slug
        title
        category {
          name
        }
        articles {
          ... on StrapiArticle {
            id
            title
            slug
            articles {
              ... on StrapiArticle {
                id
                title
                slug
              }
            }
          }
        }
      }
    }
  }
}
`;
