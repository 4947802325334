import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import {createTheme} from "@mui/material/styles";
import {LoginData} from "../../state/auth";
import {useFirebase} from "../../state/firebase/firebase-provider";
import {getAuth} from "firebase/auth";
import {
  useAuthState,
  useSendPasswordResetEmail,
} from "react-firebase-hooks/auth";
import {useI18next} from "gatsby-plugin-react-i18next";
import {RouteComponentProps} from "@reach/router";
import AuthLayout from "./AuthLayout";

const theme = createTheme();

export default function RecoverPassword(props: RouteComponentProps) {
  const {navigate} = useI18next();
  const {firebase} = useFirebase();
  const auth = getAuth(firebase!);
  const [user, loading] = useAuthState(auth);

  if (!loading && user) {
    navigate("/app/dashboard");
    return null;
  }

  const [sendResetPassword] = useSendPasswordResetEmail(auth);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData: LoginData = {
      email: data.get("email") as string,
    };
    if (loginData.email) {
      await sendResetPassword(loginData.email);
      navigate("/app/login");
    }
  };
  if (loading) {
    return null;
  }
  return (
      <AuthLayout>
        <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
          <LockOutlinedIcon/>
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset your Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate
             sx={{mt: 1}}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
          />
          <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
          >
            Send Verification Email
          </Button>
        </Box>
      </AuthLayout>
  );
}
