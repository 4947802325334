import {
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import {FirebaseContextData} from "./firebase/firebase-context";
import {User} from "firebase/auth";
import {apiUrls} from "./firebase/config";
import {RegisterForm} from "../components/auth/Register";
import {
  DocumentReference,
  Query,
  QueryDocumentSnapshot,
} from "@firebase/firestore";
import {
  accountConverter,
  balanceConverter,
  globalsConverter,
  orderConverter,
  totalConverter,
} from "./firebase/converters";

export interface Balance {
  amount: string;
  oraAmount: string;
}

export interface Order {
  id: string;
  date: string;
  bnbAmount: string;
  oraAmount: string;
  status: string;
  txid: string;
}

export interface AccountInfo {
  uid: string;
  email: string;
  firstname: string;
  lastname: string;
  phone: string;
  address: string;
  city: string;
  zip: string;
  state: string;
  country: string;
  bnbaddress: string;
  intermediateWallet: string;
  kycSubmitted: string;
  referralCode: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export type Globals = { bnbPrice: string, oraPrice: string, oraUsdValue: string, oraBnbMultiplier: number };

export type FormAccount = Omit<AccountInfo, "uid" | "intermediateWallet" | "kycSubmitted" | "referralCode">;

export const getOrders = (context: FirebaseContextData, user: User): Query<Order> => {
  const firestore = getFirestore(context.firebase!);
  const ordersRef = collection(firestore, "orders").withConverter(orderConverter);
  if (!user) {
    return query(ordersRef,
        orderBy("timeStamp", "desc"),
        limit(10));
  }
  return query(ordersRef,
      where("uid", "==", user.uid),
      orderBy("timeStamp", "desc"),
      limit(10));
};

export const getBscBalance = (context: FirebaseContextData, user: User): Query<Balance> => {
  const firestore = getFirestore(context.firebase!);
  const balanceRef = collection(firestore, "addresses").withConverter(balanceConverter);
  return query(balanceRef,
      where("owner", "==", user.uid),
      limit(1));
};

export const getUser = async (context: FirebaseContextData, user: User): Promise<QueryDocumentSnapshot<AccountInfo> | null> => {
  const firestore = getFirestore(context.firebase!);
  const usersRef = collection(firestore, "users").withConverter(accountConverter);
  const queryRef = query(usersRef,
      where("uid", "==", user.uid),
      limit(1));
  const results = await getDocs(queryRef);
  if (!results.empty) {
    return results.docs[0];
  }
  return null;
};

export const registerUser = (registerForm: RegisterForm) => {
  return fetch(apiUrls.base + apiUrls.register, {
    method: "POST",
    body: JSON.stringify(registerForm),
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const saveUser = async (
    accountRef: DocumentReference<AccountInfo>,
    updates: FormAccount) => {
  return await updateDoc(accountRef.withConverter(accountConverter), {...updates});
};


export const getTotalTokens = (context: FirebaseContextData): Query<string> => {
  const firestore = getFirestore(context.firebase!);
  const totalsRef = collection(firestore, "totals").withConverter(totalConverter);
  return query(totalsRef, limit(1));
};

export const getGlobals = (context: FirebaseContextData): Query<Globals> => {
  const firestore = getFirestore(context.firebase!);
  const globalsRef = collection(firestore, "globals").withConverter(globalsConverter);
  return query(globalsRef, limit(1));
};

