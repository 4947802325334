import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {getAuth} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {AccountInfo, FormAccount, getUser, saveUser} from "../state/store";
import Button from "@mui/material/Button";
import {FirebaseContext} from "../state/firebase";
import {Controller, useForm} from "react-hook-form";
import {DocumentReference, QueryDocumentSnapshot} from "@firebase/firestore";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";

export default function Account() {

  const context = useContext(FirebaseContext);
  const [user, loading] = useAuthState(getAuth(context.firebase));
  const [account, setAccount] = useState<DocumentReference<AccountInfo>>(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: {isSubmitting, isDirty, isValid},
  } = useForm<FormAccount>({
    mode: "onChange",
    defaultValues: {
      email: "",
      firstname: "",
      lastname: "",
      phone: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      country: "",
      bnbaddress: "",
    } as FormAccount,
  });

  useEffect(() => {
    if (!loading && user) {
      getUser(context, user).then((accountRef: QueryDocumentSnapshot<AccountInfo>) => {
        const accountInfo = accountRef.data();
        const {
          uid,
          kycSubmitted,
          intermediateWallet,
          referralCode,
          createdAt,
          updatedAt,
          ...data
        } = accountInfo;
        reset(data as FormAccount);
        setAccount(accountRef.ref);
      });
    }
  }, [loading]);
  const onSubmit = async (accountData: FormAccount) => {
    await saveUser(account, accountData);
    reset(accountData);
  };

  return (
      <Paper sx={{p: 2}}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate
             sx={{mt: 1}}>
          <Grid container spacing={3}
                justifyContent={"center"}
                alignContent={"center"}>
            <Grid item xs={12} md={6}>
              <Controller
                  name="email"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      disabled
                      id="email"
                      label="Email Address"
                      autoComplete="email"
                      autoFocus
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="firstname"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="First Name"
                      id="firstname"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="lastname"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="Last Name"
                      id="lastname"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="phone"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="Phone"
                      id="phone"
                  />}/>
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="address"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="Address"
                      id="address"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="city"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="City"
                      id="city"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="state"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="State/County"
                      id="state"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="country"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="Country"
                      id="country"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="zip"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="Zip"
                      id="zip"
                  />}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                  name="bnbaddress"
                  control={control}
                  render={({field}) => <TextField
                      {...field}
                      margin="normal"
                      fullWidth
                      label="BSC Wallet Address"
                      id="bnbaddress"
                  />}
              />
            </Grid>
            <Grid container
                  item
                  xs={12} md={6}
                  justifyContent="space-evenly">
              <LoadingButton
                  loading={isSubmitting}
                  loadingPosition="start"
                  startIcon={<SaveIcon/>}
                  variant="contained"
                  disabled={!isDirty || !isValid}
                  type="submit"
                  sx={{mt: 3, mb: 2}}
              >
                Save
              </LoadingButton>
              <Button
                  type="reset"
                  variant="contained"
                  sx={{mt: 3, mb: 2}}
                  disabled={!isDirty || !isValid || isSubmitting}
                  onClick={() => reset()}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
  );
}
