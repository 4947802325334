import * as React from "react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Container from "@mui/material/Container";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import SEO from "../SEO";

const theme = createTheme();

export interface LayoutProps {
  children: any;
}

export default function AuthLayout({children}: LayoutProps) {
  return (
      <>
        <SEO/>
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <Box
                sx={{
                  marginTop: 8,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
            >
              <Avatar sx={{m: 1, bgcolor: "secondary.main"}}>
                <LockOutlinedIcon/>
              </Avatar>
              {children}
            </Box>
          </Container>
        </ThemeProvider>
      </>
  );
}
