import * as React from "react";
import {useContext} from "react";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {FirebaseContext} from "../state/firebase";
import {useI18next} from "gatsby-plugin-react-i18next";
import Layout from "./Layout";
import {RouteComponentProps} from "@reach/router";

type PrivateRouteProps = RouteComponentProps & {
  component?: React.FC<RouteComponentProps>;
  articles?: any;
}

function PrivateRoute(props: PrivateRouteProps) {
  let {
    component: Component,
    location,
    articles,
    ...rest
  } = props;
  const {navigate} = useI18next();
  const {firebase} = useContext(FirebaseContext);
  const [user, loading] = useAuthState(getAuth(firebase));

  if (loading) {
    return null;
  }
  if (!loading && !user && location.pathname !== `/app/login`) {
    navigate("/app/login");
    return null;
  }

  // @ts-ignore
  return (
      <Layout articles={articles}>
        <Component {...rest} />
      </Layout>
  );
}

export default PrivateRoute;
