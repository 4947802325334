import * as React from "react";
import {useEffect, useState} from "react";
import {bscConfig} from "../../../state/firebase/config";
// @ts-ignore
import MetaMaskIcon from "../../../images/metamask.svg";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import TokenAmount from "./TokenAmount";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import SendIcon from "@mui/icons-material/Send";
import {BscComponentProps} from "./BSCProvider";

export default function MetaMaskButton({wallet}: BscComponentProps) {
  const [userAddress, setUserAddress] = useState<string>(null);
  const [bnbAmount, setBnbAmount] = useState<string>();
  const [open, setOpen] = React.useState(false);
  const [chainId] = React.useState(bscConfig.chainId);
  const [sending, setSending] = React.useState<boolean>(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // @ts-ignore
  if (window.ethereum === undefined || !window.ethereum?.isMetaMask) {
    return null;
  }
  // @ts-ignore
  let ethereum = window.ethereum;

  async function handleChainChanged(newChainId) {
    if (chainId && chainId !== newChainId) {
      await setBinanceChain();
      // We recommend reloading the page, unless you must do otherwise
      window.location.reload();
    }
  }

  useEffect(() => {
    ethereum.request({method: "eth_chainId"})
        .then(handleChainChanged);
    ethereum.on("chainChanged", handleChainChanged);
  }, []);

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      console.log("Please connect to MetaMask.");
    } else if (accounts[0] !== userAddress) {
      setUserAddress(accounts[0]);
    }
  };

  useEffect(() => {
    ethereum.request({method: "eth_accounts"})
        .then(handleAccountsChanged);
    ethereum.on("accountsChanged", handleAccountsChanged);
  }, []);

  // You should only attempt to request the user's accounts in response to user
// interaction, such as a button click.
// Otherwise, you popup-spam the user like it's 1999.
// If you fail to retrieve the user's account(s), you should encourage the user
// to initiate the attempt.
//   document.getElementById('connectButton', connect);

// While you are awaiting the call to eth_requestAccounts, you should disable
// any buttons the user can click to initiate the request.
// MetaMask will reject any additional requests while the first is still
// pending.
  function connect() {
    ethereum
        .request({method: "eth_requestAccounts"})
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
            console.log("Please connect to MetaMask.");
          } else {
            console.error(err);
          }
        });
  }

  /* Try to set the BinanceChain as Default */
  async function setBinanceChain() {
    try {
      await ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: chainId,
            chainName: "Binance Smart Chain",
            nativeCurrency: {
              name: "BinanceCoin",
              symbol: "BNB",
              decimals: 18, // In number form
            },
            rpcUrls: [bscConfig.rpcUrls],
            blockExplorerUrls: [bscConfig.base],
          },
        ],
      });
      switchChains();
    } catch {
      console.log("User Denied: Chain Switching or other error: " + Error);
    }
  }

  /* Try to Switch the chain to BNB*/
  function switchChains() {
    /* Switch to Binance Chain */
    ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{chainId: chainId}], // chainId must be in hexadecimal numbers
    });
  }

  function makeTransfer() {
    if (userAddress) {
      setSending(true);
      ethereum.request({
        method: "eth_sendTransaction",
        params: [
          {
            from: userAddress,
            to: wallet,
            value: bnbAmount,
          },
        ],
      }).then((txHash) => {
        console.log(txHash);
        setSending(false);
        handleClose();
      }).catch((error) => {
        console.error(error);
        setSending(false);
      });
    } else {
      connect();
    }
  }

  const updateBnbAmount = (value: any) => {
    setBnbAmount(value);
  };

  return (
      <>
        <Button variant="contained"
                onClick={handleClickOpen}>
          Use MetaMask
        </Button>
        {/* </Paper> */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Participation Amount</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Define how many BNBs you want to participate with.
            </DialogContentText>
            <TokenAmount callback={updateBnbAmount}/>
          </DialogContent>
          <DialogActions>
            <Button
                onClick={handleClose}
                disabled={sending}
            >Close</Button>
            <LoadingButton
                onClick={makeTransfer}
                endIcon={<SendIcon/>}
                loading={sending}
                loadingPosition="end"
                variant="contained"
            >Transfer</LoadingButton>
          </DialogActions>
        </Dialog>
      </>
  );
}
