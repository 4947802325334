import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {FirebaseContext} from "../../state/firebase";
import {onSnapshot} from "firebase/firestore";
import {getTotalTokens, Globals} from "../../state/store";
import {fromWei, toBN, toWei} from "web3-utils";

const BorderLinearProgress = styled(LinearProgress)(({theme}) => ({
  height: 40,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

type StatusProps = { globals: Globals };

export default function RoundStatus({globals}: StatusProps) {
  if (!globals) {
    return null;
  }
  const [available, setAvailable] = useState<string>("0");
  const [progress, setProgress] = useState<number>(0);
  const total = toWei("50000000");
  const oraValue = toBN(globals.oraBnbMultiplier);
  const firebase = useContext(FirebaseContext);

  useEffect(() => {
    onSnapshot(getTotalTokens(firebase), snapshot => {
      const totalForSale = toBN(total);
      const totalSold = snapshot.docs.map(doc => doc.data())[0];
      const totalSoldAsBN = toBN(totalSold);
      const totalAvailable = totalForSale.sub(totalSoldAsBN!.mul(oraValue));
      setAvailable(totalAvailable.toString());
      const percentAvail = totalAvailable.muln(100).div(totalForSale);
      setProgress(percentAvail.toNumber());
    });
  }, []);

  const maskMillions = (value: string) => Number.parseFloat(value).toLocaleString();
  return (
      <Box>
        <BorderLinearProgress variant="determinate"
                              value={progress}/>
        <Typography sx={{paddingTop: 2}}>
          Available: <b>{maskMillions(fromWei(available))}</b> of <b>{maskMillions(fromWei(total))}</b>
        </Typography>
      </Box>
  );
}
