import * as React from "react";
import {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {LoginData} from "../../state/auth";
import {useFirebase} from "../../state/firebase/firebase-provider";
import {getAuth} from "firebase/auth";
import {
  useAuthState,
  useSignInWithEmailAndPassword,
} from "react-firebase-hooks/auth";
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import AuthLayout from "./AuthLayout";

export default function Login(location) {
  const {navigate} = useI18next();
  const {firebase} = useFirebase();
  const auth = getAuth(firebase!);
  const [user, loading] = useAuthState(auth);
  const [loginError, setLoginError] = useState<string>("");
  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  if (!loading && user && location.pathname === "/app/login") {
    navigate("/");
    return null;
  }

  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const loginData: LoginData = {
      email: data.get("email") as string,
      password: data.get("password") as string,
    };
    if (loginData.email && loginData.password) {
      signInWithEmailAndPassword(loginData.email, loginData.password)
          .then(() => {
            if (!loading && !user) {
              setLoginError("Invalid username/password");
            }
          });
    }
  };
  if (loading) {
    return null;
  }
  return (
      <AuthLayout>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate
             sx={{mt: 1}}>
          <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
          />
          <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
          />
          <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
              disabled={loading}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/app/forgot-password">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/app/register">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
          <Typography color="error">
            {loginError}
          </Typography>
        </Box>
      </AuthLayout>
  );
}
