import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Orders from "./Orders";
import Participate from "./Participate";
import RoundStatus from "./RoundStatus";
import Title from "./Title";
import {Trans, useTranslation} from "gatsby-plugin-react-i18next";
import {onSnapshot} from "firebase/firestore";
import {getGlobals, Globals} from "../../state/store";
import {FirebaseContext} from "../../state/firebase";


export default function Dashboard() {
  const {t} = useTranslation();
  const firebase = useContext(FirebaseContext);
  const [globals, setGlobals] = useState<Globals>(null);
  useEffect(() => {
    onSnapshot(getGlobals(firebase), snapshot => {
      const result = snapshot.docs.map(doc => doc.data());
      if (result.length) {
        setGlobals(result[0]);
      }
    });
  }, []);

  return (
      <Grid container
            spacing={3}
            justifyContent="center"
            alignContent="center">
        <Grid item xs={12}>
          <Paper sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
          }}>
            <Grid container
                  spacing={3}
                  justifyContent="center">
              <Grid item xs={12}>
                <Title>
                  <Grid container
                        justifyContent={"space-between"}>
                    <Grid item>
                      <Trans>Current Round: Seed</Trans>
                    </Grid>
                    <Grid item>
                      1 BNB: {globals?.oraBnbMultiplier} ORA
                    </Grid>
                  </Grid>
                </Title>
              </Grid>
              <Grid item xs={12}>
                <RoundStatus globals={globals}/>
              </Grid>
              <Grid item
                    container
                    justifyContent="end">
                <Grid item>
                  <Participate/>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12}>
          <Orders title={t("My Participation In ICO")}
                  forUser={true}/>
        </Grid>
        {/* Pending Orders */}
        <Grid item xs={12}>
          <Orders title={t("Last 10 Ora ICO Purchases")}
                  forUser={false}/>
        </Grid>
      </Grid>
  );
}
