import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {FirebaseContext} from "../../state/firebase";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {getUser} from "../../state/store";
import ProviderMM from "./providers/ProviderMM";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {Trans} from "gatsby-plugin-react-i18next";
import {graphql, useStaticQuery} from "gatsby";
import CoinChoice from "./providers/CoinChoice";

export default function FormDialog() {
  const query = graphql`
    {
      coins: allStrapiCoin {
        edges {
          node {
            symbol
            description
            cover {
              file {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            bsc_providers {
              id
              name
              cover {
                file {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              richText: childComponentSharedRichText {
                childMdx {
                  body
                }
              }
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(query);
  const [open, setOpen] = useState(false);
  const [wallet, setWallet] = useState("");

  const context = useContext(FirebaseContext);
  const [user, loading] = useAuthState(getAuth(context.firebase));

  useEffect(() => {
    if (!loading && user) {
      getUser(context, user)
          .then(query => query.data().intermediateWallet)
          .then(setWallet);
    }
  }, [loading]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
      <React.Fragment>
        <Button variant="contained"
                onClick={handleClickOpen}
                endIcon={<ShoppingCartIcon/>}>
          Pre-order
        </Button>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle sx={{textAlign: "center"}}>
            <Trans>Participate on the Ora ICO</Trans>
          </DialogTitle>
          <DialogContent dividers>
            <CoinChoice
                coins={data.coins.edges.map(c => c.node)}
                wallet={wallet}
            />
          </DialogContent>
          <DialogActions sx={{justifyContent: "space-evenly"}}>
            <Button onClick={handleClose}
                    variant="contained">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
}
