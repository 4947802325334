import ButtonBase from "@mui/material/ButtonBase";
import * as React from "react";
// @ts-ignore
import MetaMaskIcon from "../../../images/metamask.svg";
import Grid from "@mui/material/Grid";
import {GatsbyImage} from "gatsby-plugin-image";
import Coin from "./Coin";
import Button from "@mui/material/Button";
import MobileStepper from "@mui/material/MobileStepper";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useTheme} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export interface ICoin {
  symbol: string;
  description: string;
  cover: any;
  bsc_providers: any[];
}


interface CoinChoiceProps {
  coins: ICoin[];
  wallet: string;
}

export default function CoinChoice({
  coins,
  wallet,
}: CoinChoiceProps) {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [choice, setChoice] = React.useState<any>(null);

  const handleNext = (coin: any) => () => {
    setChoice(coin);
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setChoice(null);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
      <Box paddingTop={3}>
        <Grid container
              spacing={3}
              justifyContent="center">
          {activeStep === 0 && coins &&
              coins.map((coin) => {
                return (
                    <Grid key={coin.symbol}
                          item
                          xs={12} md={6} lg={6}
                          sx={{textAlign: "center"}}>
                      <ButtonBase focusRipple
                                  onClick={handleNext(coin)}
                                  sx={{
                                    minWidth: 100,
                                    width: "100%",
                                    maxWidth: 180,
                                  }}>
                        {coin.cover && (
                            <GatsbyImage
                                image={coin.cover?.file.childImageSharp.gatsbyImageData}
                                alt={coin.symbol}/>
                        )}
                      </ButtonBase>
                      <Typography>
                        {coin.description}
                      </Typography>
                    </Grid>
                );
              })
          }
        </Grid>
        {activeStep === 1 && (
            <Coin symbol={choice.symbol}
                  cover={choice.cover}
                  bscProviders={choice.bsc_providers}
                  wallet={wallet}/>
        )
        }
        <MobileStepper
            variant="dots"
            steps={2}
            position="static"
            activeStep={activeStep}
            nextButton={
              <span>&nbsp;</span>
            }
            backButton={
              <Button size="small" onClick={handleBack}
                      disabled={activeStep === 0}>
                {theme.direction === "rtl" ? (
                    <KeyboardArrowRight/>
                ) : (
                    <KeyboardArrowLeft/>
                )}
                Back
              </Button>
            }
        />
      </Box>
  );
}
