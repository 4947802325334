import * as React from "react";
import {useState} from "react";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useFirebase} from "../../state/firebase/firebase-provider";
import {getAuth} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {Controller, useForm} from "react-hook-form";
import {registerUser} from "../../state/store";
import {Link, useI18next} from "gatsby-plugin-react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import AuthLayout from "./AuthLayout";
import TermsConditions from "./TermsConditions";
import {RouteComponentProps} from "@reach/router";
import FormControl from "@mui/material/FormControl";
import {FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {Visibility, VisibilityOff} from "@mui/icons-material";

type Error = {
  message: string
}

export type RegisterForm = {
  email: string,
  password: string,
  confirmPassword: string,
  referralCode: string,
  terms: boolean
}

export default function Register(props: RouteComponentProps) {
  const {navigate} = useI18next();
  const {firebase} = useFirebase();
  const auth = getAuth(firebase!);
  const [user, loading] = useAuthState(auth);
  const [error, setError] = useState<Error | any>({});
  const [sending, setSending] = useState<boolean>(false);
  const [showConditions, setShowConditions] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  if (!loading && user) {
    navigate("/");
    return null;
  }
  const {
    handleSubmit,
    control,
    formState: {errors, isValid},
    setValue,
    trigger,
    getValues,
  } = useForm<RegisterForm>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      referralCode: "",
      terms: false,
    },
  });

  const onSubmit = async (data: RegisterForm) => {
    try {
      setSending(true);
      const response = await registerUser(data);
      if (response.ok) {
        navigate("/app/login");
      }
      if (!response.ok) {
        setError((await response.json()).error);
      }
    } finally {
      setSending(false);
    }

  };

  const conditionsClicked = async (event) => {
    const target = event.target.checked;
    if (target) {
      setShowConditions(true);
    } else {
      setValue("terms", false);
      await trigger("terms");
    }
  };

  const conditionsDone = async (result: boolean) => {
    setShowConditions(false);
    setValue("terms", result);
    await trigger("terms");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
      <AuthLayout>
        <Typography component="h1" variant="h5">
          Signing up is easy. It only takes a few steps
        </Typography>
        <Box sx={{mt: 1}}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
                name="email"
                control={control}
                rules={{
                  required: "Required value",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Please enter a valid email",
                  },
                }}
                render={({field}) => <TextField
                    {...field}
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : null}
                    margin="normal"
                    fullWidth
                    label="Email Address"
                    autoComplete="email"
                    autoFocus
                />}
            />
            <Controller
                name="password"
                control={control}
                rules={{
                  required: "Required value",
                  minLength: {
                    value: 6, message: "Minimum length is 6",
                  },
                }}
                render={({field}) =>
                    <FormControl variant="outlined"
                                 {...field}
                                 onChange={async (event) => {
                                   field.onChange(event);
                                   await trigger("confirmPassword");
                                 }}
                                 error={!!errors.password}
                                 margin="normal"
                                 fullWidth
                    >
                      <InputLabel>Password</InputLabel>
                      <OutlinedInput
                          autoComplete="current-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                  tabIndex={-1}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                {showPassword ? <Visibility/> :
                                    <VisibilityOff/>}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Password"
                      />
                      <FormHelperText>
                        {errors.password ? errors.password.message : null}
                      </FormHelperText>
                    </FormControl>}
            />
            <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: "Required value",
                  validate: (value) => {
                    const {password} = getValues();
                    return value === password || "The passwords do not match";
                  },
                }}
                render={({field}) =>
                    <FormControl variant="outlined"
                                 {...field}
                                 error={!!errors.confirmPassword}
                                 margin="normal"
                                 fullWidth
                    >
                      <InputLabel>Password</InputLabel>
                      <OutlinedInput
                          autoComplete="new-password"
                          type={showPassword ? "text" : "password"}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                  tabIndex={-1}
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                              >
                                {showPassword ? <Visibility/> :
                                    <VisibilityOff/>}
                              </IconButton>
                            </InputAdornment>
                          }
                          label="Confirm Password"
                      />
                      <FormHelperText>
                        {errors.confirmPassword ? errors.confirmPassword.message : null}
                      </FormHelperText>
                    </FormControl>}
            />
            <Controller
                name="referralCode"
                control={control}
                rules={{required: "Required value"}}
                render={({field}) => <TextField
                    {...field}
                    error={!!errors.referralCode}
                    helperText={errors.referralCode ? errors.referralCode.message : null}
                    margin="normal"
                    fullWidth
                    label="Referral Code"
                    type="text"
                />}
            />
            <Controller
                name="terms"
                control={control}
                rules={{
                  required: "Required value",
                  validate: value => value === true || "You Need to Accept to Register",
                }}
                render={({field}) =>
                    <>
                      <FormControlLabel
                          control={<Checkbox
                              {...field}
                              checked={field.value}
                              onChange={conditionsClicked}
                              color="primary"
                          />}
                          label="Accept Terms and Conditions"
                      />
                      <FormHelperText error={!!errors.terms}>
                        {errors.terms ? errors.terms.message : null}
                      </FormHelperText>
                    </>}
            />
            <Box>
              <LoadingButton
                  loading={sending}
                  loadingPosition="start"
                  startIcon={<SaveIcon/>}
                  variant="contained"
                  disabled={!isValid}
                  type="submit"
                  fullWidth
                  sx={{mt: 3, mb: 2}}
              >
                Register
              </LoadingButton>
            </Box>
          </form>
          <Typography color="error">
            {!!error ? error.message : null}
          </Typography>
        </Box>
        <Link to="/app/login">
          {"Go to Sign In"}
        </Link>
        <TermsConditions open={showConditions}
                         onClose={conditionsDone}
        />
      </AuthLayout>
  );
}
